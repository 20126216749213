import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { Link, graphql } from 'gatsby'
import qs from 'qs'

import Layout from '../../components/layout'
import Meta from '../../components/meta'
import filterLocale from '../../components/utils/filter-locale'
import SupportSearch from '../../components/support/support-search'
import Tabs from '../../components/support/tabs'
import {
	ContentsSection,
	ContentsContainer,
	TextsImageContainer,
	Header,
	HeaderSubText,
	TextsContainer,
	LogoContainer,
	DealersContainer,
	DealerLogoContainer,
} from '../../components/dealer-search/styles'
import TypeInput from '../../components/dealer-search/type-input'
import AddressInput from '../../components/dealer-search/address-input'
import MapBox from '../../components/dealer-search/map-box'
import {
	useDealerSearch,
	useAddressSearch,
} from '../../components/dealer-search/utils'

/**
 * Find a Dealer - Page
 * @param {*} props
 * @param {object} props.data - static query data
 * @see supportFaqQuery
 */
const FindDealer = ({
	data: { finddealer, authorised, authdealers },
	location: { search },
}) => {
	const locale = 'en-NZ'
	const [{ node }] = filterLocale(finddealer.edges, locale)
	const [{ node: auth }] = filterLocale(authorised.edges, locale)
	const [{ node: authdeal }] = filterLocale(authdealers.edges, locale)

	const dealerTypes = [
		{ name: 'Elite Dealer', type: 'elite' },
	]
	const { seoTitle, seoKeywords, seoDescription } = node

	const { type } = search
		? qs.parse(search, { ignoreQueryPrefix: true })
		: { address: '', type: '' }
	const [queryAddress, onQueryAddress] = useState('')
	const [selectAddress, onSelectAddress] = useState({
		name: '',
		coordinates: [],
	})
	const [selectType, onSelectType] = useState(
		type ? dealerTypes.filter(({ name }) => name === type)[0] : dealerTypes[0]
	)
	const addresses = useAddressSearch(queryAddress, locale)
	const [dealer, setDealer] = useState({})
	const [selectDealer, onSelectDealer] = useState(0)
	const handleSelectDealer = i => {
		setDealer(dealers[i])
		onSelectDealer(i)
	}
	const { dealers, loading } = useDealerSearch(
		selectType.type,
		selectAddress.coordinates,
		setDealer,
		locale
	)

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<SupportSearch />
			<Tabs locale={locale} />
			<ContentsSection>
				<ContentsContainer>
					<TextsImageContainer>
						<TextsContainer>
							<Header>Elite Dealer Search</Header>
							<HeaderSubText>
								Are you dealing with an Authorised Maytronics Dealer? Look for
								these logos for certainty.
							</HeaderSubText>

							<LogoContainer>
								{auth.items.map((x, i) => {
									return (
										<img
											key={i}
											src={x.image.file.url}
											alt="Authorised Maytronics Dealer"
										/>
									)
								})}
							</LogoContainer>
						</TextsContainer>
					</TextsImageContainer>

					{dealer && dealer.name && (
						<div tw="flex items-center justify-center mb-4">
							<div tw="w-full max-w-sm mx-auto">
								<div tw="inline-block w-full rounded-md shadow-sm">
									<div tw="cursor-default relative flex flex-col space-y-1 w-full rounded-md border border-gray-300 bg-white p-2 text-left sm:text-sm sm:leading-5">
										{dealer.logo && (
											<img
												id="dealer-logo"
												tw="w-auto mx-auto mb-2 object-contain max-h-[100px]"
												alt={dealer.name}
												src={dealer.logo}
											/>
										)}
										<h3 tw="text-center mb-2 font-display font-bold text-base text-gray-700">
											{dealer.name}
										</h3>
										<h4 tw="text-center mb-2 font-display text-sm text-gray-700">
											{`${dealer.address.city} ${dealer.address.postalCode}`}
										</h4>
										<a
											tw="text-center mb-2 font-display font-bold text-sm text-blue-500 leading-6"
											href={'tel:' + dealer.phone}
										>
											{dealer.phone}
										</a>
										<a
											tw="text-center mb-2 font-display font-bold text-sm text-blue-500 leading-6"
											href={'mailto:' + dealer.email && dealer.email[0]}
										>
											{dealer.email && dealer.email[0]}
										</a>
										<div
											className="button-group"
											tw="inline-flex space-x-2 mx-auto"
										>
											<a
												tw="rounded-md px-3 py-2 bg-yellow-400 text-gray-600 font-bold focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 transition ease-in-out duration-150 "
												href={`https://mydolphin.co.nz/pool-cleaners/?ref=${dealer.affiliateId}`}
												target="_blank"
												rel="noreferrer noopener"
											>
												Shop
											</a>
											<a
												tw="rounded-md px-3 py-2 bg-blue-400 text-gray-600 font-bold focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
												href="/en-NZ/book-a-demo/"
												rel="noreferrer noopener"
											>
												Book a Demo
											</a>
											{/* {Boolean(dealer.type.indexOf('elite') + 1) && (
												<a
													tw="rounded-md px-3 py-2 border border-gray-300 text-gray-600 font-bold focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
													href={`https://mydolphin.com.au/pool-cleaners/
											${dealer.social.minisite}?ref=${
														dealer.affiliateId
													}&email=${dealer.email.toString()}`}
													target="_blank"
													rel="noreferrer noopener"
												>
													Profile
												</a>
											)} */}
											{/* {Boolean(dealer.type.indexOf('elitebuilder') + 1) && (
												<a
													tw="rounded-md px-3 py-2 border border-gray-300 text-gray-600 font-bold focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
													href={
														'https://builders.mydolphin.com.au/' +
														dealer.social.minisite
													}
													target="_blank"
													rel="noreferrer noopener"
												>
													Profile
												</a>
											)} */}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<AddressInput
						{...{
							queryAddress,
							onQueryAddress,
							addresses,
							selectAddress,
							onSelectAddress,
						}}
					/>
					<TypeInput {...{ dealerTypes, selectType, onSelectType }} />
					<MapBox
						{...{
							loading,
							selectAddress,
							selectType,
							dealers,
							selectDealer,
							handleSelectDealer,
							locale,
						}}
					/>
					<DealersContainer>
						<HeaderSubText>
							Maytronics Authorised Dealers are trained and local to you to
							provide support and service for your purchase of a Maytronics
							Product. Maytronics Authorised Dealers also have access to
							extended warranty. Only dealers that display the logo below meet
							Maytronics stringent requirements to be authorised. If you are not
							sure if a Dealer is authorised, please contact us direct on{' '}
							<a href="tel:1300693657">1300 693 657</a> or 
							<a href="mailto:infoau@maytronics.com">infoau@maytronics.com</a>.
						</HeaderSubText>
						<HeaderSubText>
							In addition to our Elite Dealers, we also authorise the following
							distribution networks
						</HeaderSubText>

						<DealerLogoContainer>
							{authdeal.items.map((x, i) => {
								return (
									<a
										key={i}
										href={x.url}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={x.image.file.url}
											alt="Maytronics Authorised Dealers"
										/>
									</a>
								)
							})}
						</DealerLogoContainer>
					</DealersContainer>
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}

/**
 * Page Query
 * @static graphql query from Contentful
 */
export const supportFaqQuery = graphql`
	query findDealerQueryNz {
		finddealer: allContentfulPage(
			filter: { contentful_id: { eq: "6LAjCqRqIKB2gF4WIQdY1G" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
				}
			}
		}
		authorised: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "4nSUnNIpIcguqEKR16Pevu" } }
		) {
			edges {
				node {
					items {
						url
						image {
							file {
								url
							}
						}
					}
					node_locale
				}
			}
		}
		authdealers: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "50phjcIV3OsUVeAvJXDGyG" } }
		) {
			edges {
				node {
					items {
						url
						image {
							file {
								url
							}
						}
					}
					node_locale
				}
			}
		}
	}
`

FindDealer.propTypes = {
	data: PropTypes.shape({
		finddealer: PropTypes.objectOf(PropTypes.array),
		authorised: PropTypes.objectOf(PropTypes.array),
		authdealers: PropTypes.objectOf(PropTypes.array),
	}),
	location: PropTypes.shape({
		search: PropTypes.string,
	}),
}

export default FindDealer
